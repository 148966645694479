/*Threefold Magic*/
$light_shades: #6a488e;
$light_accent: #6a488e;
$main_brand_color: #6a488e;
$dark_accent: #6a488e;
$dark_shades: #6a488e;

$white: #fdf9ed;
$black: #302533;
$gray: #7c7171;

$linkBlue: #238be0;
$deleteRed: #ff3d3d;

$box_shadow: 0px 0px 7px 0px
  rgba(
    $color: $main_brand_color,
    $alpha: 0.25,
  );
$box_shadow_black: 0px 0px 5px 0px
  rgba(
    $color: $black,
    $alpha: 0.1,
  );
$box_shadow_icons: 0px -10px 7px 0px rgba(
    $color: $black,
    $alpha: 0.1,
  );
$box_shadow_inset: inset 0px 0px 25px 5px
  rgba(
    $color: $black,
    $alpha: 0.1,
  );
$box_shadow_hover: 0px 0px 7px 0px
  rgba(
    $color: $black,
    $alpha: 0.15,
  );

$themes: (
  light: (
    background: $black,
    text: $white,
  ),
  dark: (
    background: $white,
    text: $black,
  ),
);

@mixin theme() {
  @each $theme, $map in $themes {
    $theme-map: $map !global;
    .#{$theme} & {
      @content;
    }
  }
  $theme-map: null !global;
}

@function theme-get($key) {
  @return map-get($theme-map, $key);
}

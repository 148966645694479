@use "@/styles/colors";

.card {
  min-height: fit-content;
  min-width: fit-content;
  // Base styles for the card
  position: relative;
  transition: all 0.3s ease-in-out;
  width: auto;
  max-width: min(50rem, calc(100% - 2rem));
}

.cardNoMax {
  position: relative;
  transition: all 0.3s ease-in-out;
  max-width: calc(100vw - 2rem);
}

.before {
  position: absolute;
  transition: all 0.3s ease-in-out;
  border: 0.2rem solid colors.$main_brand_color;
  border-radius: 2.5rem;
  pointer-events: none;
  background: colors.$white;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

// Define rotation classes
@for $i from 0 through 360 {
  .rotate-#{$i} {
    transform: rotate(#{$i}deg);
  }
  .-rotate-#{$i} {
    transform: rotate(-#{$i}deg);
  }
}

// Define hover rotation classes
@for $i from 0 through 360 {
  .hover\:rotate-#{$i}:hover {
    transform: rotate(#{$i}deg);
  }
  .hover\:-rotate-#{$i}:hover {
    transform: rotate(-#{$i}deg);
  }
}

@media (max-width: 1200px) {
  .before {
    display: none;
    max-width: calc(75%);
    max-height: calc(75%);
  }
}
